.searchbox {
    width: auto
}

.searchbox .searchbox__input {
    padding-right: calc(1ex + 2rem);
    transition: width .5s .25s ease, background-color .5s .25s ease
}

.searchbox .searchbox__input:focus {
    transition: width .5s .25s ease, background-color .5s .25s ease
}

.searchbox .searchbox__input.bg-transparent {
    background-color: transparent !important;
    border: 0;
    box-shadow: none !important;
    color: inherit
}

.searchbox .searchbox__input.bg-transparent:focus {
    background-color: rgba(0, 0, 0, .15) !important
}

.searchbox.input-group:not(.has-validation)>.searchbox__input:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-radius: .4375rem
}

.searchbox .searchbox__btn {
    font-size: 1rem;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .5s .25s ease, visibility .5s .25s ease;
    z-index: 5
}

.searchbox.searchbox--auto-expand .searchbox__input {
    width: 20vw
}

.searchbox.searchbox--auto-expand .searchbox__input:focus {
    width: 35vw
}

.searchbox.searchbox--hide-btn .searchbox__btn {
    opacity: 0;
    visibility: hidden
}

.searchbox.searchbox--hide-btn .searchbox__input:focus~.searchbox__btn,
.searchbox.searchbox--hide-btn .searchbox__input:focus~div>.searchbox__btn {
    opacity: 1;
    transition: opacity .5s ease, visibility .5s ease;
    visibility: visible
}

.scroll-container {
    pointer-events: none;
    position: absolute;
    right: 1rem;
    top: Min(calc(100% - 4em), 120vh);
    bottom: 4em;
    clip-path: inset(0 0 0 0);
    z-index: 999
}

.scroll-page {
    align-items: center;
    background-color: #25476a;
    border: 4px solid #edf1f6 !important;
    border-radius: .4375rem;
    color: #fff !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    pointer-events: visible;
    position: sticky;
    right: 1rem;
    text-decoration: none;
    top: calc(100vh - 4em - 1rem);
    width: 4em;
    z-index: 99999
}

.scroll-page:after {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: inline-block;
    height: .75em;
    left: calc(50% - .375em);
    pointer-events: none;
    position: absolute;
    top: calc(50% - .175em);
    transform-origin: center center;
    transform: rotate(-45deg);
    width: .75em
}

.root:before {
    background-color: rgba(0, 0, 0, .55);
    content: "";
    display: block;
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    visibility: hidden;
    z-index: -1
}

.root.mn--show:before,
.root.sb--show:before {
    cursor: pointer;
    opacity: 1;
    pointer-events: visible;
    visibility: visible;
    z-index: 9998
}

.timeline {
    display: flex;
    flex-direction: column;
    gap: var(--timeline-entry-gap)
}

.timeline {
    --timeline-dot: var(--bs-primary);
    --timeline-line: var(--bs-primary);
    --timeline-line-width: 2px;
    --timeline-point-gap: 0.25rem;
    --timeline-point-size: 0.5rem;
    --timeline-media-size: 2.25rem;
    --timeline-entry-gap: 1.5rem
}

.timeline .tl-time:not(:empty) {
    min-width: 7rem;
    text-align: end
}

.timeline .tl-time .tl-date {
    font-size: 90%
}

.timeline .tl-time .tl-time {
    color: var(--bs-headings-color);
    font-weight: 700
}

.timeline .tl-entry {
    display: flex;
    gap: .75rem
}

.timeline .tl-point:before {
    background-color: var(--timeline-dot);
    border-radius: 50%;
    content: "";
    display: block;
    height: var(--timeline-point-size);
    transform: translateY(.75ex);
    width: var(--timeline-point-size)
}

.timeline .tl-point:after {
    border-inline-start: var(--timeline-line-width) solid var(--timeline-line);
    content: "";
    display: block;
    height: calc((100% - var(--timeline-point-size) - (var(--timeline-point-gap) * 2)) + var(--timeline-entry-gap));
    inset: calc(.75ex + var(--timeline-point-gap)) auto auto calc(50% - (var(--timeline-line-width)/ 2));
    position: relative
}

.timeline .tl-media {
    flex: 0 0 var(--timeline-media-size)
}

.timeline .tl-media i {
    align-items: center;
    display: flex;
    justify-content: center
}

.timeline .tl-media:after {
    border-inline-start: var(--timeline-line-width) solid var(--timeline-line);
    content: "";
    display: block;
    height: calc((100% - var(--timeline-media-size) - (var(--timeline-point-gap) * 2)) + var(--timeline-entry-gap));
    inset: var(--timeline-point-gap) auto auto calc(50% - (var(--timeline-line-width)/ 2));
    position: relative
}

.timeline .tl-entry.active .tl-icon .ratio {
    box-shadow: 0 0 0 var(--timeline-line-width), 0 0 0 calc(var(--timeline-line-width) * 2) var(--bs-primary)
}

.timeline .tl-entry.active .tl-point:before {
    background-color: transparent;
    box-shadow: 0 0 0 var(--timeline-line-width) var(--timeline-dot)
}

.timeline .tl-entry.active .tl-point:after {
    height: calc((100% - var(--timeline-line-width) - var(--timeline-point-size) - (var(--timeline-point-gap) * 2)) + var(--timeline-entry-gap));
    top: calc(.75ex + var(--timeline-point-gap) + var(--timeline-line-width))
}

.timeline .tl-entry:last-child .tl-media:after,
.timeline .tl-entry:last-child .tl-point:after {
    border-color: var(--bs-muted-color);
    border-inline-start-style: dotted
}

.timeline-two-column .tl-content,
.timeline-two-column .tl-entry>.tl-time {
    width: 50%
}

.timeline-two-column .tl-content>* {
    display: inline-flex;
    flex-direction: column
}

.timeline-two-column .tl-entry-start {
    flex-direction: row-reverse
}

.timeline-two-column .tl-entry-start .tl-time {
    text-align: start
}

.timeline-two-column .tl-entry-start .tl-content {
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
    /* justify-content: end */
    justify-content: flex-end;
}

.bubble {
    background-color: var(--bs-card-bg);
    border-radius: 1.5rem;
    border-end-start-radius: 0;
    color: var(--bs-card-color);
    display: inline-flex;
    flex-direction: column;
    max-width: Min(400px, 80%);
    padding: .5rem 1rem;
    position: relative
}

.bubble:after {
    border-color: transparent transparent var(--bs-card-bg) transparent;
    border-style: solid;
    border-width: 0 0 5px 7px;
    bottom: 0;
    content: "";
    display: block;
    left: -7px;
    position: absolute;
    width: 0;
    z-index: 1
}

.bubble-primary .bubble,
.bubble.bubble-primary {
    background-color: var(--bs-comp-active-bg);
    border-radius: 1.5rem;
    border-end-end-radius: 0;
    color: var(--bs-comp-active-color)
}

.bubble-primary .bubble:after,
.bubble.bubble-primary:after {
    border-color: transparent transparent transparent var(--bs-comp-active-bg);
    border-width: 5px 0 0 7px;
    left: auto;
    right: -7px
}

.root {
    display: grid;
    gap: 0 0;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content 1fr;
    min-height: 100vh;
    width: 100%
}

:root {
    --bs-body-bg: #edf1f6;
    --bs-body-color: #75868f;
    --bs-card-bg: #fff;
    --bs-dropdown-bg: #fff;
    --bs-headings-color: #373c43;
    --bs-muted-color: #a5b1b8;
    --bs-link-color: #03a9f4;
    --bs-comp-active-bg: #25476a;
    --bs-comp-active-color: #fff;
    --bs-border-color: rgba(0, 0, 0, 0.07);
    --bs-body-bg-rgb: 237, 241, 246;
    --bs-body-color-rgb: 117, 134, 143;
    --bs-card-bg-rgb: 255, 255, 255;
    --bs-dropdown-bg-rgb: 255, 255, 255;
    --bs-headings-color-rgb: 55, 60, 67;
    --bs-muted-color-rgb: 165, 177, 184;
    --bs-link-color-rgb: 3, 169, 244;
    --bs-comp-active-bg-rgb: 37, 71, 106;
    --bs-comp-active-color-rgb: 255, 255, 255;
    --bs-border-radius: 0.4375rem
}

body {
    overflow-x: hidden;
    position: relative;
    background-size: cover;
    background-attachment: fixed
}

.vr,
hr {
    background-color: rgba(0, 0, 0, .07);
    opacity: 1
}

.front-container {
    background-color: #edf1f6;
    color: #75868f;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content
}

.front-container .header {
    grid-area: 1/1/2/2
}

.front-container .content {
    background-color: transparent;
    color: inherit;
    grid-area: 2/1/3/2
}

.front-container .footer {
    grid-area: 3/1/4/2
}

.bg-img:not(.boxed-layout)>.front-container,
body[style*=background]:not(.boxed-layout)>.front-container {
    background-color: transparent
}

.content {
    background-color: #edf1f6;
    color: #75868f;
    display: flex;
    flex-direction: column;
    grid-area: 2/2/3/3;
    width: 100%;
    min-width: 0;
    max-width: 100vw
}

.content .page-title {
    color: #25476a;
    font-size: 2rem;
    font-weight: 400
}

.content__wrap {
    padding: 1rem 1rem
}

.root:not(.hd--expanded) .content__header>.content__wrap {
    padding-bottom: 0 !important
}

@media (min-width:768px) {
    .content__wrap {
        padding-inline: 1.25rem
    }
}

@media (min-width:992px) {
    .content__wrap {
        padding-inline: 1.25rem
    }
}

@media (min-width:1200px) {
    .content__wrap {
        padding-inline: 1.25rem
    }
}

.content-full-page {
    position: fixed;
    inset: 0;
    z-index: 999999;
    border-radius: 0 !important
}

.body-sc>.root {
    position: relative;
    height: 100vh;
    overflow: hidden;
    z-index: 999999
}

.body-sc .content__header:after,
.body-sc .header,
.body-sc .sidebar {
    z-index: 0 !important
}

.single-content {
    display: flex;
    min-height: 0 !important;
    flex: 1 1 0
}

@media (min-width:576px) {
    .single-content-sm {
        display: flex;
        min-height: 0 !important;
        flex: 1 1 0
    }
}

@media (min-width:768px) {
    .single-content-md {
        display: flex;
        min-height: 0 !important;
        flex: 1 1 0
    }
}

@media (min-width:992px) {
    .single-content-lg {
        display: flex;
        min-height: 0 !important;
        flex: 1 1 0
    }
}

@media (min-width:1200px) {
    .single-content-xl {
        display: flex;
        min-height: 0 !important;
        flex: 1 1 0
    }
}

@media (min-width:1400px) {
    .single-content-xxl {
        display: flex;
        min-height: 0 !important;
        flex: 1 1 0
    }
}

.header {
    background-color: #25476a;
    color: #dee3e9;
    grid-area: 1/1/2/3
}

.header__inner {
    align-items: center;
    display: flex;
    height: 3.125rem;
    padding-inline: 1rem
}

.header__brand {
    background-color: transparent;
    color: #fff;
    align-items: center;
    display: flex;
    height: 100%
}

.brand-wrap {
    align-items: center;
    display: flex;
    position: relative
}

.brand-wrap:hover {
    transition: transform .5s ease;
    transform: scale(1.1)
}

.brand-img {
    margin-inline-end: .5rem;
    text-align: center;
    width: auto
}

.brand-img img {
    width: 27px;
    height: 27px
}

.brand-title {
    display: none
}

.header__content {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: space-between
}

.header__content-start {
    flex-grow: 1
}

.header__content-end,
.header__content-start {
    align-items: center;
    display: flex;
    gap: .3rem
}

.header__content-end>*,
.header__content-start>* {
    margin: 0 -.1rem
}

.header__btn {
    border-radius: .875rem;
    box-shadow: none !important;
    color: inherit;
    font-size: .8rem
}

.header__btn:focus,
.header__btn:hover {
    background-color: #1f3c5a;
    color: #fff
}

.header-searchbox {
    position: relative;
    margin-left: auto
}

.searchbox__backdrop {
    position: relative
}

.hd--sticky .header {
    position: sticky;
    top: 0;
    z-index: 1000
}

.hd--sticky.mn--sticky.mn--max .mainnav .mainnav__inner {
    height: calc(100vh - 3.125rem);
    top: 3.125rem
}

@media (max-width:767.98px) {
    .header__content .searchbox {
        background-color: #25476a;
        left: 0;
        padding-left: var(--bs-gutter-x, .75rem);
        padding-right: var(--bs-gutter-x, .75rem);
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 9999
    }

    .header__content .searchbox .searchbox__input {
        position: fixed;
        top: -99rem
    }

    .header__content .searchbox .searchbox__input,
    .header__content .searchbox .searchbox__input:focus~.searchbox__backdrop {
        margin-bottom: calc(2px + .3625rem);
        margin-top: calc(2px + .3625rem)
    }

    .header__content .searchbox .searchbox__btn,
    .header__content .searchbox .searchbox__input {
        transition-duration: 0s
    }

    .header__content .searchbox .searchbox__input:focus {
        background-color: #1f3c5a !important;
        position: static
    }

    .header__content .searchbox .searchbox__input:focus~.searchbox__backdrop:after {
        background-color: rgba(0, 0, 0, .5);
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: fixed;
        right: 0;
        top: 0
    }

    .header__content .dropdown-menu {
        margin-inline: .5rem !important;
        max-width: calc(100vw - 1rem);
        width: 100vw
    }

    .hd--sticky .header__content .dropdown-menu {
        max-height: calc(100vh - 3.125rem - .5rem);
        overflow-x: hidden
    }
}

@media (min-width:768px) {
    .brand-img {
        display: none
    }

    .header-searchbox {
        margin: 0
    }

    .brand-title {
        color: #fff !important;
        display: block;
        font-family: Ubuntu, sans-serif;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.25rem;
        margin-inline: .5rem;
        text-decoration: none !important
    }

    .header .searchbox .searchbox__input.bg-transparent {
        border-radius: .875rem !important
    }

    .header .searchbox .searchbox__input.bg-transparent:focus {
        background-color: #1f3c5a !important
    }

    .searchbox__backdrop {
        height: 100%;
        margin: 0 !important;
        position: absolute;
        right: 0;
        top: 0
    }
}

@media (min-width:992px) {
    .mn--max .header__brand {
        transition: max-width .5s ease;
        width: 100%
    }
}

@media (min-width:768px) {
    .header__inner {
        padding-inline: 1.25rem
    }
}

@media (min-width:992px) {
    .header__inner {
        padding-inline: 1.25rem
    }
}

@media (min-width:1200px) {
    .header__inner {
        padding-inline: 1.25rem
    }
}

.hd--expanded .content__header {
    background-color: #25476a;
    border-radius: 0 0 100% 100%/0 0 3vw 3vw;
    color: #cfd6df;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%
}

.hd--expanded .content__header :where(h1, h2, h3, h4, h5, h6) {
    color: #fff
}

.hd--expanded .content__header .text-head {
    color: #fff !important
}

.hd--expanded .content__header .text-muted {
    color: rgba(255, 255, 255, .75) !important
}

.hd--expanded .content__header .page-title {
    color: #fff
}

.hd--expanded .content__header .page-title:after {
    background-color: #fff
}

.hd--expanded .content__header .breadcrumb-item.active,
.hd--expanded .content__header .breadcrumb-item:before {
    color: #cfd6df
}

.hd--expanded .content__header .breadcrumb-item>a {
    color: #fff
}

.hd--expanded .content__header .btn-primary {
    background-color: #1e3955;
    border-color: #1e3955
}

.hd--expanded .content__header .btn-link {
    color: #c0eafc
}

.hd--expanded .content__header .btn-link.active,
.hd--expanded .content__header .btn-link:active,
.hd--expanded .content__header .btn-link:focus,
.hd--expanded .content__header .btn-link:hover {
    color: #74d0f9
}

.hd--expanded .content__header.overlapping .content__wrap:after {
    content: "";
    display: block;
    height: 12.5vw;
    width: 100%
}

.hd--expanded .content__header.overlapping+.content__boxed {
    margin-top: calc(-12.5vw - 2rem);
    z-index: 99
}

.hd--expanded .content__header:not(.overlapping):not(.rounded-0) {
    min-height: 10.9375rem
}

.hd--expanded.mn--max.mn--sticky .content__header:before,
.hd--expanded.mn--min.mn--sticky .content__header:before {
    position: sticky;
    top: -.06125rem
}

.hd--expanded.mn--max.hd--sticky .content__header:not(.rounded-0):before,
.hd--expanded.mn--max.mn--sticky .content__header:not(.rounded-0):before,
.hd--expanded.mn--min.hd--sticky .content__header:not(.rounded-0):before,
.hd--expanded.mn--min.mn--sticky .content__header:not(.rounded-0):before {
    margin-bottom: 2.1875rem
}

.hd--expanded.mn--max.hd--sticky .content__header:before,
.hd--expanded.mn--min.hd--sticky .content__header:before {
    position: sticky;
    top: 3.125rem
}

.hd--expanded.mn--max.hd--sticky .content__header:not(.rounded-0) .content__wrap,
.hd--expanded.mn--max.mn--sticky .content__header:not(.rounded-0) .content__wrap,
.hd--expanded.mn--min.hd--sticky .content__header:not(.rounded-0) .content__wrap,
.hd--expanded.mn--min.mn--sticky .content__header:not(.rounded-0) .content__wrap {
    margin-top: -3.28125rem
}

.hd--expanded.mn--max .content__header .content__wrap,
.hd--expanded.mn--min .content__header .content__wrap {
    margin-top: -1.09375rem
}

.hd--expanded.mn--max .content__header:before,
.hd--expanded.mn--min .content__header:before {
    background-color: #25476a;
    content: "";
    display: block;
    height: 1.09375rem;
    margin-left: 0;
    -webkit-mask-image: radial-gradient(circle at 0 1.09375rem, transparent 0, transparent 1.09375rem, #000 1.09375rem);
    mask-image: radial-gradient(circle 1.09375rem at 0 1.09375rem, transparent 0, transparent 1.09375rem, #000 1.09375rem);
    position: relative;
    top: -.06125rem;
    transform: translateX(-1.09375rem);
    width: 1.155rem;
    z-index: 10
}

.hd--fair .header__inner {
    padding-inline-start: 0 !important
}

.hd--fair.mn--max .header__brand,
.hd--fair.mn--min .header__brand {
    background-color: #fff;
    color: #25476a
}

.hd--fair .header__brand {
    background-color: transparent;
    color: #fff;
    padding-inline-start: 1rem;
    margin-inline-end: .5rem
}

.hd--fair .header__brand .brand-title {
    color: inherit !important
}

.hd--fair.hd--expanded .content__header:before {
    visibility: hidden
}

@media (min-width:990px) {
    .hd--fair.mn--max .header__brand {
        margin-inline-end: 0 !important
    }

    .centered-layout .hd--fair .header__brand {
        padding-inline: 1.25rem
    }

    .centered-layout .hd--fair.mn--max .header__inner,
    .centered-layout .hd--fair.mn--min .header__inner {
        max-width: none !important
    }

    .centered-layout .hd--fair.mn--min .header__brand {
        width: 100%;
        max-width: 3.75rem;
        justify-content: center;
        padding: 0;
        margin-inline-end: 0 !important
    }

    .centered-layout .hd--fair.mn--min .header__brand .brand-title {
        display: none
    }

    .centered-layout .hd--fair.mn--max .header__brand {
        width: 13.75rem
    }
}

@media (min-width:1210px) {
    .centered-layout .hd--fair.mn--max .header__brand {
        padding-inline: 1.25rem
    }
}

@media (min-width:1200px) {
    .centered-layout .hd--fair.mn--max .header__brand {
        padding-inline: 1.25rem
    }
}

.mainnav__caption {
    color: #373c43
}

.mainnav__inner .mainnav__menu .nav-link {
    padding-block: .59rem
}

.mainnav__inner .mainnav__menu>.nav-item>.nav-link {
    display: flex
}

.mainnav__inner .mainnav__menu>.nav-item>.nav-link.active {
    background-color: #25476a;
    border-radius: .4375rem;
    box-shadow: none;
    color: #fff;
    font-weight: 700
}

.mainnav__inner .mainnav__menu>.nav-item>.nav-link.active .nav-icon,
.mainnav__inner .mainnav__menu>.nav-item>.nav-link.active i {
    color: inherit !important
}

.mainnav__inner .mainnav__menu>.nav-item>.nav-link:not(.collapsed):not(.active) {
    color: #25476a
}

.root .mainnav__inner .nav-link {
    color: #75868f
}

.root .mainnav__inner .nav-link.disabled {
    opacity: .4
}

.root .mainnav__inner .nav-link:hover {
    color: #25476a
}

.root .mainnav__inner .nav-link.active~.nav .active {
    color: #25476a
}

.root .mainnav__inner .nav-link.active~.nav .active:hover {
    color: #25476a
}

.root .mainnav__inner .nav-link .nav-icon,
.root .mainnav__inner .nav-link i {
    color: #33475c !important
}

.mainnav__menu .mininav-content {
    flex-direction: column;
    flex-wrap: nowrap
}

.mainnav__menu .mininav-toggle {
    align-items: center;
    display: flex
}

.mainnav__menu .mininav-toggle .nav-icon,
.mainnav__menu .mininav-toggle i,
.mainnav__menu .mininav-toggle>.nav-label {
    pointer-events: none
}

.mainnav__menu .mininav-toggle .nav-label {
    display: inline-block
}

.mainnav__menu .has-sub>.mininav-toggle:not(.has-badge):after {
    border-style: solid;
    border-width: .15em .15em 0 0;
    content: '';
    display: inline-block;
    height: .55em;
    margin-left: auto;
    transform: rotate(135deg);
    transition: transform .25s;
    width: .55em
}

.mainnav__menu .has-sub>.mininav-toggle.collapsed:after {
    transform: rotate(45deg)
}

.root:not(.mn--min) .mainnav__menu .has-sub.open>.mininav-content {
    display: block;
    height: auto !important
}

.root:not(.mn--min) .mainnav__menu .has-sub.open>.mininav-toggle:after {
    transition: none !important;
    transform: rotate(135deg) !important
}

@media (max-width:991.98px) {
    .root .mininav-content {
        position: static !important;
        transform: none !important
    }

    .root .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content {
        border-inline-start: 1px dashed rgba(55, 60, 67, .45);
        border-radius: .4375rem;
        margin-inline-start: calc(.25em + 1rem) !important;
        padding-inline-start: .5rem
    }

    .root .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content>.nav-item>.nav-link {
        padding-inline: .75em
    }

    .root .mainnav__menu>.nav-item.has-sub>.mininav-content {
        border-inline-start: 1px dashed rgba(55, 60, 67, .45);
        margin-inline-start: 1.75em !important
    }

    .root .mainnav__menu>.nav-item.has-sub>.mininav-content>.nav-item>.nav-link {
        padding-inline-start: calc(.5em + 1rem)
    }
}

@media (min-width:992px) {
    .root:not(.mn--min) .mininav-content {
        position: static !important;
        transform: none !important
    }

    .root:not(.mn--min) .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content {
        border-inline-start: 1px dashed rgba(55, 60, 67, .45);
        margin-inline-start: calc(.25em + 1rem);
        padding-inline-start: .5rem
    }

    .root:not(.mn--min) .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content>.nav-item>.nav-link {
        padding-inline: .75em
    }

    .root:not(.mn--min) .mainnav__menu>.nav-item.has-sub>.mininav-content {
        border-inline-start: 1px dashed rgba(55, 60, 67, .45);
        margin-inline-start: 1.75em
    }

    .root:not(.mn--min) .mainnav__menu>.nav-item.has-sub>.mininav-content>.nav-item>.nav-link {
        padding-inline-start: calc(.5em + 1rem)
    }
}

@media (min-width:992px) {
    .mn--min {
        padding: 0 !important
    }

    .mn--min .mainnav {
        max-width: 3.75rem;
        transition: max-width .5s ease;
        will-change: max-width;
        z-index: auto
    }

    .mn--min .header__inner {
        padding-left: 0
    }

    .mn--min .header__brand {
        justify-content: center;
        max-width: 3.75rem;
        padding-inline-start: 0;
        transition: max-width .5s ease;
        width: 100%;
        will-change: max-width
    }

    .mn--min.mn--sticky.hd--expanded .content__header::before {
        z-index: 100
    }

    .mn--min.mn--sticky.hd--expanded .mainnav {
        z-index: 99
    }

    .mn--min.hd--sticky .mainnav__inner {
        top: 3.125rem;
        z-index: auto
    }

    .mn--sticky.mn--min.hd--sticky .mainnav__inner {
        height: calc(100vh - 3.125rem)
    }

    .mn--min .mainnav__profile .mininav-content {
        min-width: 13rem
    }

    .mn--min .mainnav__avatar {
        height: auto;
        width: 2rem
    }

    .mn--min .d-mn-min {
        display: block
    }

    .mn--min .brand-title,
    .mn--min .d-mn-max,
    .mn--min .d-mn-max:not(.show),
    .mn--min .mainnav__caption,
    .mn--min .mainnav__menu>.has-sub>.nav-link:after,
    .mn--min .mainnav__menu>.nav-item>.nav-link .nav-label:not(.show) {
        display: none
    }

    .mn--min .brand-img {
        display: block
    }

    .mn--max .brand-img {
        display: none
    }

    .mn--min .mainnav__menu>.nav-item>.nav-link {
        justify-content: center
    }

    .mn--min .mainnav__menu>.nav-item>.nav-link .nav-icon,
    .mn--min .mainnav__menu>.nav-item>.nav-link i {
        color: #75868f !important
    }

    .mn--min .mainnav__menu>.nav-item>.nav-link.active {
        background-color: #25476a;
        color: #fff
    }

    .mn--min .mainnav__menu>.nav-item>.nav-link.active .nav-icon,
    .mn--min .mainnav__menu>.nav-item>.nav-link.active i {
        color: #fff !important
    }

    .mn--min .mainnav__menu>.nav-item,
    .mn--min .mainnav__menu>.nav-item>.nav-link i {
        margin: 0 !important
    }

    .mn--min .mainnav__menu>.nav-item {
        margin-bottom: .25rem !important;
        width: 100%
    }

    .mn--min .mainnav__menu>.has-sub .has-sub>.nav-link {
        gap: 2.5rem
    }

    .mn--min .mininav-toggle {
        cursor: pointer
    }

    .mn--min .mininav-toggle .nav-label {
        color: #75868f
    }

    .mn--min .mininav-content {
        padding: .5rem .25rem;
        background-color: #fff;
        border-radius: .4375rem;
        box-shadow: .25rem 0 1.5rem 0 rgba(0, 0, 0, .2);
        min-width: 11rem;
        max-width: 19rem;
        white-space: nowrap;
        margin-inline-start: Max(1rem, .25rem) !important
    }

    .mn--min .mininav-content .nav-link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }

    .mn--min .mininav-content.show {
        position: fixed;
        top: -100rem;
        z-index: 100;
        transition: transform .15s linear .1s
    }

    .mn--min .mininav-content:not(.nav) {
        padding: .75rem .25rem
    }

    .mn--min .mininav-content.nav-label {
        padding: .75rem 1.5rem
    }

    .mn--min .mininav-content.collapsing {
        transition: 0s !important
    }

    .mn--min .mainnav__menu>.nav-item.has-sub .has-sub>.mininav-content {
        margin-inline-start: .25rem !important
    }

    .mn--min .mainnav__top-content {
        flex: 1 1 0;
        min-height: 0 !important
    }

    .mn--min .mainnav__bottom-content {
        z-index: 100
    }
}

@media (min-width:992px) {
    .mn--max .mainnav {
        position: relative
    }

    .mn--max .mainnav__inner {
        position: absolute
    }

    .mn--max .mainnav {
        transition: max-width .5s ease;
        will-change: max-width
    }

    .mn--max .header__inner {
        padding-inline-start: 0
    }

    .mn--max .header__content,
    .mn--min .header__content {
        padding-inline-start: 1.25rem
    }

    .mn--max .header__brand {
        padding-inline-start: 1.25rem;
        max-width: 13.75rem;
        width: 13.75rem;
        will-change: max-width
    }
}

@media (min-width:1200px) {
    .mn--max .header__brand {
        padding-inline-start: 1.25rem
    }
}

.mainnav {
    display: flex;
    grid-area: 2/1/3/2;
    max-width: 13.75rem;
    width: 13.75rem
}

.mainnav .scrollable-content {
    scrollbar-width: none
}

.mainnav__inner {
    background-color: #fff;
    color: #75868f;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%
}

.mainnav__inner :where(h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6):not(.mainnav__caption) {
    color: #373c43
}

.mainnav__inner :where(.border-top, .border-end, .border-bottom, .border-start) {
    border-color: #e1e2e3 !important
}

.mainnav__inner .dropdown-toggle:after {
    color: #373c43
}

.mainnav__inner .text-muted {
    color: #c3c5c7 !important
}

.d-mn-max,
.d-mn-max.collapse {
    display: block
}

.d-mn-min {
    display: none
}

.mainnav__avatar.border {
    padding: .1rem
}

.mainnav__top-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    width: 100%
}

.mainnav__top-content.os-host-overflow {
    overflow: visible
}

.mainnav__bottom-content {
    background-color: #fff;
    bottom: 0;
    margin-top: auto;
    position: sticky
}

.mainnav__bottom-content,
.mainnav__top-content {
    padding-inline: .75rem
}

@media (min-width:768px) {

    .mainnav__bottom-content,
    .mainnav__top-content {
        padding-inline: Max(.75rem, .25rem)
    }
}

@media (min-width:992px) {

    .mainnav__bottom-content,
    .mainnav__top-content {
        padding-inline: Max(.75rem, .25rem)
    }
}

@media (min-width:1200px) {

    .mainnav__bottom-content,
    .mainnav__top-content {
        padding-inline: Max(.75rem, .25rem)
    }
}

.mn--show .mainnav {
    transform: translateX(0);
    z-index: 9999
}

.mainnav,
.mn--show .mainnav {
    transition: transform .5s ease
}

.mn--sticky .mainnav__inner {
    height: 100vh;
    position: sticky;
    top: 0
}

.root:not(.mn--max):not(.mn--min) .mainnav {
    position: absolute;
    inset: 0 auto
}

.root:not(.mn--max):not(.mn--min):not(.mn--show) .mainnav {
    transform: translateX(-13.8125rem);
    z-index: 1002
}

@media (max-width:991.98px) {
    .mainnav__menu .mininav-toggle .nav-label {
        position: static !important;
        transform: none !important
    }

    .mn--min .mainnav {
        inset: 0 auto;
        position: absolute
    }

    .mn--min:not(.mn--show) .mainnav {
        transform: translateX(-13.8125rem);
        z-index: 1002
    }
}

@media (max-width:991.98px) {
    .mn--max .mainnav {
        inset: 0 auto;
        position: absolute
    }

    .mn--max:not(.mn--show) .mainnav {
        transform: translateX(-13.8125rem);
        z-index: 1002
    }
}

.mn--push .mainnav {
    transform: translateX(-13.8125rem)
}

.mn--push .content,
.mn--push .header,
.mn--push .mainnav,
.mn--push.mn--show .content,
.mn--push.mn--show .header,
.mn--push.mn--show .mainnav {
    transition: transform .5s ease
}

.mn--push.mn--show .mainnav {
    transform: translateX(0);
    z-index: 9999
}

.mn--push.mn--show .content,
.mn--push.mn--show .header {
    transform: translateX(13.75rem)
}

.mn--push.mn--show.sb--show.sb--pinned .sidebar {
    transform: translateX(13.75rem)
}

.mn--reveal .mainnav {
    pointer-events: none;
    transform: translateX(0) !important;
    z-index: 5 !important
}

.mn--reveal .header {
    z-index: 12
}

.mn--reveal .content,
.mn--reveal .sidebar {
    z-index: 10
}

.mn--reveal.sb--pinned .sidebar {
    z-index: 11
}

.mn--reveal .content,
.mn--reveal .header,
.mn--reveal.mn--show .content,
.mn--reveal.mn--show .header {
    transition: transform .5s ease
}

.mn--reveal.mn--show .mainnav {
    pointer-events: visible
}

.mn--reveal.mn--show .content,
.mn--reveal.mn--show .header,
.mn--reveal.mn--show:before {
    transform: translateX(13.75rem)
}

.mn--reveal.mn--show:before {
    transition: opacity .5s, transform .5s !important
}

.mn--reveal.mn--show.sb--show.sb--pinned .sidebar {
    transform: translateX(13.75rem)
}

.sidebar {
    align-items: stretch;
    background-color: #fff;
    box-sizing: content-box;
    color: #75868f;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(17.1875rem);
    visibility: hidden;
    width: 0;
    z-index: 1002
}

.sidebar .scrollable-content {
    scrollbar-width: none
}

.sidebar__inner {
    height: 100vh;
    overflow-x: hidden;
    padding-block: 1.5rem;
    position: sticky;
    top: 0
}

.sidebar__wrap {
    padding-inline: .5rem
}

.sb--show .sidebar {
    overflow: visible;
    transform: translateX(0);
    visibility: visible;
    width: 17.1875rem;
    z-index: 9999
}

.sidebar {
    transition: visibility .5s, width 0s linear .5s, padding .5s ease, transform .5s ease
}

.sb--show .sidebar {
    transition: visibility .5s, padding .5s ease, transform .5s ease
}

@media (min-width:1195px) {
    .sb--pinned .sidebar {
        overflow: visible;
        transform: translateX(0);
        visibility: visible;
        width: 17.1875rem;
        z-index: 9999
    }

    .sidebar {
        transition: visibility .5s, width 0s linear .5s, padding .5s ease, transform .5s ease
    }

    .sb--pinned .sidebar {
        transition: visibility .5s, padding .5s ease, transform .5s ease
    }
}

.sidebar__stuck {
    display: none
}

@media (min-width:768px) {
    .sidebar__wrap {
        padding-inline: Max(.5rem, .25rem)
    }
}

@media (min-width:992px) {
    .sidebar__wrap {
        padding-inline: Max(.5rem, .25rem)
    }
}

@media (min-width:1200px) {
    .sidebar__wrap {
        padding-inline: Max(.5rem, .25rem)
    }
}

.sb--bd-0.sb--show:before {
    opacity: 0
}

.sb--bd-0.sb--show .sidebar {
    box-shadow: 0 .5rem 3rem rgba(55, 60, 67, .5)
}

.sb--static .sidebar__inner {
    overflow: visible;
    position: static
}

.sidebar__stuck {
    display: none
}

.sb--stuck.sb--show:before {
    opacity: 0;
    pointer-events: none;
    visibility: hidden
}

.sb--stuck.sb--show .sidebar {
    box-shadow: 0 .5rem 3rem rgba(55, 60, 67, .5)
}

.sb--stuck .sidebar__stuck {
    display: flex
}

.sb--unite .sidebar {
    background-color: #25476a;
    color: #dee3e9
}

.sb--unite .sidebar .nav-link.active,
.sb--unite .sidebar .nav-link:focus,
.sb--unite .sidebar .nav-link:hover,
.sb--unite .sidebar :where(h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6) {
    color: #fff
}

.sb--unite .sidebar .text-muted {
    color: rgba(222, 227, 233, .85) !important
}

.sb--unite .sidebar .btn-primary,
.sb--unite .sidebar .form-switch .form-check-input:checked {
    background-color: #516c88;
    border-color: #516c88
}

.sb--unite .sidebar .btn-link {
    color: #c0eafc
}

.sb--unite .sidebar .btn-link.active,
.sb--unite .sidebar .btn-link:active,
.sb--unite .sidebar .btn-link:focus,
.sb--unite .sidebar .btn-link:hover {
    color: #74d0f9
}

.sb--unite .sidebar .nav-callout {
    border-color: #415f7e
}

.sb--unite .sidebar .nav-callout .nav-link.active {
    box-shadow: inset 0 -.1875rem #fff
}

.sb--unite .sidebar .nav-link {
    color: #dee3e9
}

.sb--unite .sidebar .nav-link .nav-icon,
.sb--unite .sidebar .nav-link i {
    color: rgba(222, 227, 233, .65) !important
}

.sb--unite .sidebar .list-group-item,
.sb--unite .sidebar .list-group-item-action {
    color: #dee3e9
}

.sb--unite .sidebar .list-group-item-action:focus,
.sb--unite .sidebar .list-group-item-action:hover {
    background-color: #395877;
    color: #fff
}

@media (min-width:1195px) {
    .sb--pinned:not(.mn--show):before {
        opacity: 0;
        pointer-events: none;
        visibility: hidden
    }

    .sb--pinned.hd--sticky .sidebar__inner {
        top: 3.125rem
    }

    .sb--pinned .content__boxed {
        padding-right: 17.1875rem
    }

    .sb--pinned:not(.mn--push):not(.mn--reveal) .sidebar {
        transition-duration: 0s !important
    }

    .sb--pinned:not(.sb--unite) .sidebar {
        background-color: transparent
    }

    .sb--pinned:not(.sb--unite) .sidebar .sidebar__inner {
        background-color: #edf1f6;
        color: #75868f
    }

    .sb--pinned:not(.sb--static) .sidebar .sidebar__inner {
        height: calc(100vh - 3.125rem);
        overflow-x: hidden
    }

    .sb--pinned .sidebar {
        height: calc(100% - 3.125rem);
        top: 3.125rem;
        z-index: 999
    }

    .sb--pinned .sidebar .sidebar__inner {
        border-top-left-radius: 1.09375rem
    }

    .sb--pinned.sb--unite .sidebar__inner {
        border-radius: 0
    }

    .sb--pinned.sb--unite .sidebar:after {
        background-color: #25476a;
        content: "";
        display: block;
        height: 1.09375rem;
        margin-left: -1.09375rem;
        margin-top: -100vh;
        -webkit-mask-image: radial-gradient(circle at 0 1.09375rem, transparent 0, transparent 1.09375rem, #000 1.15625rem);
        mask-image: radial-gradient(circle at 0 1.09375rem, transparent 0, transparent 1.09375rem, #000 1.15625rem);
        position: relative;
        top: 3.125rem;
        width: 1.155rem
    }

    .sb--pinned.sb--unite.hd--sticky .sidebar:after {
        position: sticky
    }

    .sb--pinned.sb--unite.hd--expanded .content__header:after {
        align-self: flex-end;
        background-color: #25476a;
        content: "";
        display: block;
        height: 1.15625rem;
        -webkit-mask-image: radial-gradient(circle at 0 1.09375rem, transparent 1.09375rem, #000 1.15625rem);
        mask-image: radial-gradient(circle at 0 1.09375rem, transparent 1.09375rem, #000 1.15625rem);
        position: absolute;
        top: 100%;
        transition: transform .5s ease;
        width: 1.15625rem;
        z-index: 1000
    }

    .sb--pinned.sb--unite.hd--expanded .content__header {
        border-radius: 0 !important
    }
}

@media (min-width:990px) {
    .centered-layout .sb--pinned:not(.sb--unite) .sidebar__inner {
        border-radius: 1.09375rem
    }

    .centered-layout .sb--pinned .content__header {
        padding-inline-end: calc(((100vw - 990px)/ 2) + 17.1875rem) !important;
        width: auto !important
    }

    .centered-layout .sb--pinned .content__boxed {
        padding-inline-end: calc(17.1875rem + 1.25rem + .3125rem)
    }

    .centered-layout .sb--pinned .sidebar {
        padding-right: calc(((100vw - 990px)/ 2))
    }

    .centered-layout .mn--max.sb--pinned .content__boxed,
    .centered-layout .mn--min.sb--pinned .content__boxed {
        padding-inline-end: calc((((100vw - 990px)/ 2) + 17.1875rem))
    }

    .centered-layout .mn--max.sb--pinned .sidebar,
    .centered-layout .mn--min.sb--pinned .sidebar {
        padding-right: calc(((100vw - 990px)/ 2))
    }
}

.in-quart .mn--reveal.mn--show:before,
.in-quart :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned.content__boxed) {
    transition-timing-function: cubic-bezier(.755, .045, .915, .135) !important
}

.out-quart .mn--reveal.mn--show:before,
.out-quart :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned.content__boxed) {
    transition-timing-function: cubic-bezier(.015, .77, .04, .985) !important
}

.in-back .mn--reveal.mn--show:before,
.in-back :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned.content__boxed) {
    transition-timing-function: cubic-bezier(.705, -.155, .735, .045) !important
}

.out-back .mn--reveal.mn--show:before,
.out-back :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned.content__boxed) {
    transition-timing-function: cubic-bezier(.28, 1.02, .36, 1.145) !important
}

.in-out-back .mn--reveal.mn--show:before,
.in-out-back :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned.content__boxed) {
    transition-timing-function: cubic-bezier(1, -.28, 0, 1.275) !important
}

.steps .mn--reveal.mn--show:before,
.steps :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned.content__boxed) {
    transition-timing-function: cubic-bezier(0, .955, 1, .045) !important
}

.jumping .mn--reveal.mn--show:before,
.jumping :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned.content__boxed) {
    transition-timing-function: cubic-bezier(.005, 1.045, .25, .915) !important
}

.rubber .mn--reveal.mn--show:before,
.rubber :where(.mainnav, .content, .header, .header__brand, .sidebar, .offcanvas, .collapsing, .searchbox__input, .dropdown-menu, .mininav-toggle:after, .sb--pinned.content__boxed) {
    transition-timing-function: cubic-bezier(.135, 1.525, 0, .9) !important
}

.scrollable-content,
body,
html {
    scrollbar-color: rgba(135, 139, 144, .5) #fff0;
    scrollbar-width: thin
}

.scrollable-content::-webkit-scrollbar,
body::-webkit-scrollbar,
html::-webkit-scrollbar {
    width: .1875rem
}

.scrollable-content::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
    background-color: transparent
}

.scrollable-content::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
    background: rgba(135, 139, 144, .5);
    border-radius: 2rem
}

.scrollable-content::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover,
html::-webkit-scrollbar-thumb:hover {
    background: rgba(135, 139, 144, .5);
    width: .625rem
}

.dropdown-menu,
.offcanvas-body,
.sidebar {
    content-visibility: auto
}

.dropdown-menu img[loading=lazy],
.offcanvas img[loading=lazy],
.sidebar img[loading=lazy] {
    display: none
}

.dropdown-menu.show img[loading=lazy],
.offcanvas.show img[loading=lazy],
.sb--show .sidebar img[loading=lazy] {
    display: unset
}

@media (min-width:1195px) {
    .sb--pinned .sidebar img[loading=lazy] {
        display: unset
    }
}

.object-fill {
    object-fit: fill
}

.object-contain {
    object-fit: contain
}

.object-cover {
    object-fit: cover
}

.object-none {
    object-fit: none
}

.object-scale-down {
    object-fit: scale-down
}

@page {
    margin: 0 auto;
    size: 8.5in 11in
}

@media print {

    #root,
    .content,
    body,
    html {
        background-color: transparent
    }

    #root {
        display: block;
        color: #555e69;
        background-color: #fff
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #30353b
    }

    .content {
        color: inherit !important
    }

    .badge {
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }

    .card {
        background-color: transparent;
        box-shadow: none
    }

    .bg-light {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        background-color: #ecf1f4 !important
    }

    .text-muted {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #727e8c !important
    }

    .table {
        border-color: #ecf1f4 !important;
        color: #30353b !important
    }

    .table thead th,
    .table thead tr {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        background-color: transparent !important;
        color: #30353b !important
    }

    .table tbody td,
    .table tbody tr {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        border-color: #ecf1f4;
        color: #30353b !important
    }

    .table tbody td {
        background-color: transparent;
        box-shadow: none
    }

    .table.table-striped tbody tr:nth-child(odd) td {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        background-color: #ecf1f4
    }

    #_dm-settingsToggler,
    #mainnav-container,
    .content__header,
    .header,
    .scroll-container,
    .sidebar,
    footer {
        display: none !important
    }

    .hd--expanded .content__header.overlapping+.content__boxed {
        margin-top: 0 !important
    }

    .d-lg-flex,
    .d-md-flex,
    .d-xl-flex {
        display: flex !important
    }
}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right
}

.ribbon span {
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #e53f50;
    box-shadow: 0 3px 10px -5px #000;
    position: absolute;
    top: 19px;
    right: -21px
}

.ribbon span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #e53f50;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #e53f50
}

.ribbon span:after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #e53f50;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #e53f50
}

.error-code {
    font-size: 9rem !important;
    font-weight: 400;
    line-height: 1
}

@media (min-width:Max(1024px, 1200px)) {
    .boxed-layout {
        background-attachment: fixed;
        background-color: #878787;
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0 2rem 1rem rgba(55, 60, 67, .25);
        margin: 0 auto;
        max-width: 1200px;
        padding-top: 1rem;
        padding-inline-end: 0 !important
    }

    .boxed-layout .root {
        -webkit-clip-path: inset(0 0 0 0 round .75rem);
        clip-path: inset(0 0 0 0 round .75rem)
    }

    .boxed-layout .mn--reveal.mn--show:before {
        max-width: 1200px;
        margin: 0 auto;
        transition: transform .5s ease !important;
        transform: translateX(calc((100% - 1200px) + 13.75rem))
    }

    .boxed-layout .mega-dropdown {
        max-width: calc(75 * 1200px / 100)
    }

    .boxed-layout .searchbox.searchbox--auto-expand .searchbox__input {
        max-width: 30vw
    }

    .boxed-layout .sb--pinned .sidebar {
        top: 4.1875rem
    }
}

@media (min-width:990px) {
    .centered-layout .hd--expanded:not(.mn--max):not(.mn--min) .content__header {
        box-sizing: content-box;
        margin: 0;
        padding-inline: calc(((100% - 990px)/ 2) - 1.25rem);
        width: calc(990px + 2.5rem)
    }

    .centered-layout .content__boxed,
    .centered-layout .footer,
    .centered-layout .header__inner {
        margin: 0 auto;
        min-width: calc(990px + 2.5rem);
        max-width: calc(990px + 2.5rem)
    }

    .centered-layout .mn--max .content__boxed,
    .centered-layout .mn--max .header__inner {
        max-width: none;
        margin: 0 auto
    }

    .centered-layout .mn--min .header__inner {
        padding-inline-start: 1.25rem
    }

    .centered-layout .mn--min .header__brand {
        width: auto;
        max-width: none;
        justify-content: start
    }

    .centered-layout .mn--min .brand-title {
        display: block
    }

    .centered-layout .mn--min .content__boxed {
        margin: 0;
        max-width: none;
        padding-left: calc(((100vw - 990px)/ 2) - 3.75rem - 1.25rem);
        padding-right: calc(((100vw - 990px)/ 2) - 1.25rem)
    }

    .centered-layout .mn--min .content__boxed:not(.content__header) {
        min-width: 100%;
        max-width: min-content
    }

    .centered-layout .mn--min .content__header:before {
        transform: translateX(Min(-1.03125rem, calc((((990px - 100vw)/ 2) + 3.75rem + 1.25rem) - 1.09375rem)))
    }
}

@media (min-width:1210px) {

    .centered-layout .mn--max .content__boxed,
    .centered-layout .mn--max .header__inner {
        margin: 0 auto;
        max-width: calc(990px + 2.5rem)
    }

    .centered-layout .mn--max .header__brand {
        width: auto
    }

    .centered-layout .mn--max .content__boxed {
        margin: 0;
        max-width: none;
        padding-left: calc(((100vw - 990px)/ 2) - 13.75rem - 1.25rem);
        padding-right: calc(((100vw - 990px)/ 2) - 1.25rem)
    }

    .centered-layout .mn--max .content__boxed:not(.content__header) {
        min-width: 100%;
        max-width: min-content
    }

    .centered-layout .mn--max .content__header:before {
        transform: translateX(Min(-1.03125rem, calc((((990px - 100vw)/ 2) + 13.75rem + 1rem))))
    }
}

.box-shadow{
    box-shadow: 0px 5px 5px #00000033
}
.slider{
    background-color: grey
}
.body{
    background-image:url('./assets/img/fondo.png');
    width: 1920px;
    height: 1000px;
}

/* Modal */
.ReactModalPortal > div{
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    z-index: 999;
}

.modal-fondo {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 100000 !important;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-height: 680.5px;
    max-width: 800px;
    outline: none;
    padding: 0px;
    z-index: 1100 !important; /*Posición de la modal*/
}


/* Alertas */
.alertaExpander{
    position: absolute; 
    inset: 0px auto auto 0px; 
    margin: 0px; 
    transform: translate(-264px, 38px);
}
.userExpander{
    position: absolute; 
    inset: 0px auto auto 0px; 
    margin: 0px; 
    transform: translate(-414px, 38px);
}

.swal2-popup {
    font-size: 0.6rem !important;
    font-family: Georgia, serif;
}

.swal2-container {
    z-index: 999999 !important; /*Este debe ser mayor al del modal*/
}

/* Dropdown MUI Component Autocomplete*/
div[role="presentation"].MuiAutocomplete-popper {
    z-index: 2000 !important; /*Este debe ser mayor a la modal y menor al swal2*/
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
    padding: 0;
}

.grecaptcha-badge {
    visibility : hidden;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spinner-backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 999999;
}

th {
    padding: 0.1rem !important;
}

td {
    padding: 0.4rem !important;
}